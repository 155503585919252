.card__detail {
  width: 85%;
  margin: auto;
  background-color: #f2f2f2;
  padding: 40px;
  /* border: 2px solid red; */
}
.card__detail img {
  /* max-width: 600px; */
  max-width: 100%;
  height: auto;
  margin: 30px 0;
  /* border: 2px solid red; */
}
.cardDetail__txt {
  /* border: 2px solid red; */
  font-size: 35px;
}
@media screen and (max-width: 700px) {
  .cardDetail__txt {
    /* border: 2px solid red; */
    font-size: 25px;
  }
}
@media screen and (max-width: 700px) {
  .card__detail img {
    max-width: 100%;
    height: auto;
    margin: 30px 0;
    /* border: 2px solid red; */
  }
}
.card__detail p {
  /* border: 2px solid red; */
  padding: 4px 0px;
  line-height: 1.7;
}
@media screen and (max-width: 600px) {
  .card__detail {
    width: 90%;
    margin: auto;
    background-color: #f2f2f2;
    padding: 10px;
  }
}
.cardDetail__border {
  border-top: 1px solid rgba(0, 0, 0, 0.508);
  border-bottom: 1px solid rgba(0, 0, 0, 0.472);
  padding: 10px;
}
.cardDetail__input {
  border-radius: 10px;
}
.card__icons a {
  color: black;
}
.card__icons {
  /* border: 2px solid red; */

  cursor: pointer;
}
.card__icons .icon1 {
  /* border: 2px solid red; */
  font-size: 40px;

  cursor: pointer;
}
.card__icons .icon2 {
  /* border: 2px solid red; */
  font-size: 40px;

  cursor: pointer;
}

.card__icons .icon3 {
  /* border: 2px solid red; */
  font-size: 40px;

  cursor: pointer;
}
.card__icons .icon4 {
  /* border: 2px solid red; */
  font-size: 40px;

  /* cursor: pointer; */
}
