/* @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;600;700&family=Roboto:ital,wght@0,300;0,400;0,500;1,100;1,700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;600;700&family=Prompt:wght@300;500&family=Roboto:ital,wght@0,300;0,400;0,500;1,100;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
:root {
  --whiteColor: white;
  /* --blackColor: black, */
}
body {
  /* font-family: "Kanit", sans-serif;
  font-family: "Roboto", sans-serif; */
  font-family: "Kanit", sans-serif;
  font-family: "Prompt", sans-serif;
  font-family: "Roboto", sans-serif;
}
a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}
ul li {
  list-style: none;
}
