body {
  background-color: #f2f2f2;
}
.places__page {
  width: 90%;
  background-color: #fff;
  margin: auto;
  padding: 20px;
}

@media screen and (max-width: 599px) {
  .places__page {
    width: 81%;
    background-color: #fff;
    margin: auto;
  }
}

@media screen and (max-width: 365px) {
  .places__page {
    width: 95%;
    background-color: #fff;
    margin: auto;
  }
}
