body {
  background-color: #f2f2f2;
}
.cards img {
  /* max-width: 100%; */

  width: 100%;
}
.cards {
  margin: 20px;
  cursor: pointer;
}

.main__Sec {
  width: 87%;

  background-color: white;
  margin: auto;
  padding: 20px;
}

@media screen and (max-width: 320px) {
  .main__Sec {
    width: 100%;

    background-color: white;
    margin: auto;
    padding: 20px;
  }
}
