.placeCard {
  position: relative;
  /* border: 2px solid red; */
  height: 100%;
  width: 100%;
}
.placeCard__div {
  width: 100%;
  height: 250px;
  position: relative;
  /* border: 2px solid rgb(47, 0, 255); */
}
.placeCard__div img {
  width: 100%;
  cursor: pointer;
  height: 100%;
  /* border: 2px solid red; */
  object-fit: cover;
}
.placeCard__div img:hover {
  transform: scale(1.1);
  overflow-y: hidden;
 
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  z-index: -1;
  /* height: 100%; */
}
.card__heart {
  position: absolute;
  top: 5%;
  left: 5%;
  color: white;
  background-color: rgb(57, 57, 128);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 20px; */
}
.cart__heartIcon {
  font-size: 15px;
  /* border: 2px solid red; */
}
.card__feature {
  position: absolute;
  z-index: 4;
  left: 15px;
  top: 72px;
  color: #fff;
  cursor: pointer;
  font-size: 0.845em;
  padding: 3px 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #f75c96;
}

.card__feature::before {
  content: "";
  position: absolute;
  top: 0;
  /* bottom: auto; */
  border-right: 20px solid transparent;
  border-top: 20px solid #f75c96;
  left: 91%;
}

.card__feature::after {
  content: "";
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 92%;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #f75c96;
}

.card__iconImg::before {
  content: "";
  position: absolute;
  bottom: 23px;
  left: 0;
  right: 0;
  height: 20px;
  border-radius: 15px 15px 0 0;
  background: #fff;
  z-index: 3;
  width: 100%;
  height: 21px;
}

.card__iconImg img {
  position: absolute;
  top: -124%;
  left: 76%;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  z-index: 99;
}
.cardicon__line {
  position: relative;
  height: 33px;
  width: 100%;
}
.cardRating__stars {
  width: 10px;
  display: flex;
}
.stars__icons {
  width: 10px;
  color: #f0c439;
}
.card__counter {
  position: absolute;
  top: 20px;
  right: 20px;
  color: rgb(255, 255, 255);
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 5px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: #5ecfb1;
}
hr {
  opacity: 0.6;
  margin-top: 10px;
}
