.rice__types {
  width: 90%;
  background-color: #fff;
  margin: auto;
  padding: 20px;
}
.rice__Types {
  width: 100%;
  height: 100%;
}
.riceImage__div {
  width: 100%;
  margin-right: 10px;
  object-fit: contain;
}
.ricetypes__img {
  width: 95%;
  height: 300px;
  cursor: pointer;
}
.riceTypes__card {
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
}
.riceTypes__Img {
  cursor: pointer;
  width: 100%;
  height: 300px;
  object-fit: contain;
}
.riceCard {
  margin-top: 40px;
}
.grainTypes__card {
  cursor: pointer;
}
