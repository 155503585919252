body {
  background: #f2f2f2;
}
.news__page {
  width: 90%;
  margin: auto;
  background: white;
  padding: 50px 50px;
}
@media screen and (max-width: 476px) {
  .news__page {
    width: 100%;
    margin: auto;
    background: white;
    padding: 50px 26px;
  }
}
.news__card {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}
.news__card img {
  width: 100%;
  /* height: 300px; */
  height: 270px;
  object-fit: cover;
}

/* .newsCard__image {
  border: 2px solid red;
  max-width: 100%;
  object-fit: contain;
} */
.news__date {
  /* border: 2px solid red; */
  position: absolute;
  top: 20px;
  right: 20px;
  color: rgb(255, 255, 255);
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 5px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: #5ecfb1;
}
