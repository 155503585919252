body {
  background-color: #f2f2f2;
}
.place__details {
  width: 90%;
  background-color: #fff;
  margin: auto;
  padding: 20px;
}
.place__details img {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.placeDetail__description {
  box-shadow: 1px -1px 14px 11px rgba(228, 220, 220, 0.75);
  margin-top: 30px;
  padding: 20px;
  border-radius: 20px;
}
@media screen and (max-width: 1024px) {
}
.placeDetail__description {
  box-shadow: 1px -1px 14px 11px rgba(228, 220, 220, 0.75);
  margin-top: 10px;
  padding: 20px;
  border-radius: 20px;
}
.place__details p {
  line-height: 1.8;
}
.contactButton {
  background: #004d70;
  color: white;
  font-family: inherit;
  padding: 0.45em;
  padding-left: 1em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #004d70;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3em;
  margin: 20px 0px;
}

.iconButton {
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #004d70;
  right: 0.3em;
  transition: all 0.3s;
}

.contactButton:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em #004d70;
}

.contactButton:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em #004d70;
}
.placecard__right {
  box-shadow: 1px -1px 14px 11px rgba(228, 220, 220, 0.75);
  padding: 20px;
  margin: 0px 25px;
  border-radius: 20px;
  /* border: 2px solid red; */
}
@media screen and (max-width: 900px) {
  .placecard__right {
    box-shadow: 1px -1px 14px 11px rgba(228, 220, 220, 0.75);
    padding: 30px;
    margin: 50px 0px;
  }
}

@media screen and (max-width: 420px) {
  .placecard__right {
    box-shadow: 1px -1px 14px 11px rgba(228, 220, 220, 0.75);
    padding: 10px;
    margin: 30px 0px;
  }
}
@media screen and (max-width: 375px) {
}

.placeDetail__Website {
  color: black;
  width: 100%;
  word-wrap: break-word;
}
.placeDetail__Email {
  width: 100%;
  word-wrap: break-word;
  font-size: 16px;
}

.placeDetail__icons {
  color: black;
}
