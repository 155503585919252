body {
  font-family: "Kanit", sans-serif;
  font-family: "Prompt", sans-serif;
  font-family: "Roboto", sans-serif;
}
:root {
  --blue: #59a3d9;
}
.main__footer {
  background-color: var(--blue);
  width: 100%;
}
.footer {
  background-color: var(--blue);
  display: flex;

  justify-content: center;

  width: 90%;
  margin: auto;
}
.prefooter__icons ul li{


}
.footer__left {
  width: 80%;
  margin: auto;
}
.footer__thi {
  width: 75%;
  margin: auto;
}

.footer__left li {
  color: white;

  margin-bottom: 10px;
  font-size: 20px;
  list-style-type: disc;
}
.footer__left .lists__head {
  font-size: 30px;
  color: white;
}

.footer__lists {
  list-style-type: disc;

  margin-bottom: 14px;
  font-size: 20px;
  color: white;
}

.pre__bgimage {
  /* background: rgb(13, 61, 77); */
  background-color: var(--blue);
  height: 50px;
  border-bottom: 1px solid rgb(147, 185, 219);
}
.prefooter__icons {
  display: flex;
  /* border: 2px solid red; */
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: auto;
  color: white;
  height: 50px;
}
.prefooter__icons ul {
  display: flex;
  align-items: center;
  list-style: none;
  /* border: 2px solid red; */
}
.prefooter__icons li {
  padding: 4px 10px;
  cursor: pointer;
  /* border: 2px solid red; */
}
@media screen and (max-width: 600px) {
  .footer__right {
    text-align: center;
    list-style: none;
  }
  .footer__left {
    text-align: center;
  }

  .footer__left li {
    color: white;
    margin-bottom: 10px;
    font-size: 20px;
    list-style-type: none;
  }
  .footer__thi {
    text-align: center;
  }
  .footer__thi li {
    list-style-type: none;
    text-align: center;
  }
  .footer__second {
    text-align: center;
    list-style: none;
    /* border: 2px solid red; */
  }
  .footer__second li {
    list-style: none;
  }
}
.footer__logo {
  width: 200px;
  cursor: pointer;
}
