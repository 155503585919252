body {
  background-color: #f2f2f2;
}
.about__page {
  width: 85%;
  background-color: #fff;
  margin: auto;
  padding: 20px;
}
.about__img {
  /* border: 2px solid red; */
  height: 229px;
  width: 229px;
}
.about__page P {
  line-height: 1.8;
}
