body {
  background-color: #f2f2f2;
}
.contact__page {
  width: 85%;
  background-color: #fff;
  margin: auto;
  padding: 20px;
}
.error{

  color: red;

}
.news__address{
    /* border: 2px solid red; */
    font-size: 30px;

}
@media screen and (max-width: 425px){
  .news__address{
    /* border: 2px solid red; */
    font-size: 25px;
}
}