.nav__lists {
  display: flex;
  padding: 0px 20px;
}

.nav__lists li {
  list-style: none;
  padding: 0px 24px;
}
@media screen and (max-width: 957px) {
  .nav__lists li {
    list-style: none;
    padding: 0px 18px;
  }
}
@media screen and (max-width: 899px) {
  .nav__lists li {
    list-style: none;
    padding: 0px 12px;
  }
}
@media screen and (max-width: 800px) {
  .nav__lists {
    display: none;
  }
}

.header__bgImg {
  background-image: url("../../assests/logo/headerBg.png");
  height: 30vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.header__imgLogo {
  position: absolute;
  left: 10%;
  top: 10%;
  height: 100px;
  width: 350px;
  /*  */
  /* border: 2px solid red; */
}
.header__Logo {
  max-width: 100%;
  height: 100%;

  object-fit: contain;
}

@media screen and (max-width: 476px) {
  .header__imgLogo {
    position: absolute;
    width: 280px;
  }
  .header__imgLogo img {
    width: 100%;
  }
}
.cards__section {
  padding: 30px;
}
.card__Topline {
}
.cardTopline__txt {
  display: flex;
}
.cardTopline__txt h4 {
  font-size: 30px;
}
.cardTopline__txt__sech4 {
  color: grey;
}
@media only screen and (max-width: 486px) {
  .cardTopline__txt h4 {
    font-size: 20px;
  }
}
.cardTopline__txt .hr {
  font-weight: 900;
  border: 2px solid rgb(0, 0, 0);
  margin: 0px 10px;
}
.card__Topline__hr {
  border: 2px solid black;
  font-weight: 500;
  margin: 30px 0px;
}
.cards__section {
  width: 90%;
  margin: auto;
  background: rgb(224, 230, 224);
  padding: 30px;
}
.cards__section2 {
  width: 90%;
  margin: auto;
}
.news__head {
  margin: 30px 0px 30px 0px;
  padding: 14px 10px;
  color: white;
  font-weight: 800;
  background-color: #59a3d9;
  font-size: 20px;
}
.side__form {
  background-image: url("../../assests/images/newsletter.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: 100%;
  padding: 10px;
  text-align: center;
}
.side__form p {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  color: rgb(13, 13, 104);
  margin-top: 10px;
}
.side__form span {
  color: white;
}
.side__form p:nth-child(2) {
  color: rgb(225, 225, 46);
  margin: 30px 0;
}
.side__form p:nth-child(3) {
  font-size: 20px;
}
.side__form input {
  margin: 10px 0px;
  padding: 8px;
  border-radius: 20px;
}
.side__form button,
.side__form button:focus {
  font-size: 17px;
  padding: 10px 25px;
  border-radius: 0.7rem;
  background-image: linear-gradient(
    rgb(62, 31, 163),
    rgba(142, 117, 34, 0.785)
  );
  border: 2px solid rgb(50, 50, 50);
  border-bottom: 5px solid rgb(50, 50, 50);
  box-shadow: 0px 1px 6px 0px rgb(158, 129, 254);
  transform: translate(0, -3px);
  transition: 0.2s;
  transition-timing-function: linear;
  margin: 20px 0px;
}

button:active {
  transform: translate(0, 0);
  border-bottom: 2px solid rgb(50, 50, 50);
}
@media screen and (max-width: 1199px) {
  .side__form {
    display: none;
  }
}
.news__card {
  position: relative;
}
.drawer__logo {
  width: 70px;
  margin: 0 10px;
}
.grain__cards {
  /* border: 2px solid red; */
  height: 100%;
  width: 100%;
  position: relative;
}

.grain__cards img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.drawer__logo {
  /* border: 2px solid red; */
  width: 50%;
}
.homePlace__counter {
  position: absolute;
  top: 20px;
  right: 20px;
  color: rgb(255, 255, 255);
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 0px 5px;
  padding: 6px 5px;
  border-radius: 20px;
  background-color: #5ecfb1;
}
.homePlace__txt {
  position: absolute;
}

/* Loader */

/* //////Card  */
#container {
  width: 100px;
  height: 125px;
  margin: auto auto;
}

.loading-title {
  display: block;
  text-align: center;
  font-size: 20;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  padding-bottom: 15px;
  color: #888;
}

.loading-circle {
  display: block;
  border-left: 5px solid;
  border-top-left-radius: 100%;
  border-top: 5px solid;
  margin: 5px;
  animation-name: Loader_611;
  animation-duration: 1500ms;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.sp1 {
  border-left-color: #f44336;
  border-top-color: #f44336;
  width: 40px;
  height: 40px;
}

.sp2 {
  border-left-color: #ffc107;
  border-top-color: #ffc107;
  width: 30px;
  height: 30px;
}

.sp3 {
  width: 20px;
  height: 20px;
  border-left-color: #8bc34a;
  border-top-color: #8bc34a;
}

@keyframes Loader_611 {
  0% {
    transform: rotate(0deg);
    transform-origin: right bottom;
  }

  25% {
    transform: rotate(90deg);
    transform-origin: right bottom;
  }

  50% {
    transform: rotate(180deg);
    transform-origin: right bottom;
  }

  75% {
    transform: rotate(270deg);
    transform-origin: right bottom;
  }

  100% {
    transform: rotate(360deg);
    transform-origin: right bottom;
  }
}
