.button {
  --color: #004d70;
  padding: 0.6em 1.5em;
  background-color: transparent;
  border-radius: 0.3em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 400;
  font-size: 17px;
  border: 1px solid;
  font-family: inherit;
  text-transform: uppercase;
  color: var(--color);
  z-index: 1;
  margin-top: 20px;
}

.button::before,
.button::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color);
  transition: 1s ease;
}

.button::before {
  top: -1em;
  left: -1em;
}

.button::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.button:hover::before,
.button:hover::after {
  height: 410px;
  width: 410px;
}

.button:hover {
  color: rgb(155, 181, 190);
}

.button:active {
  filter: brightness(0.8);
}

.ck__editor {
  border: 2px solid red;
}

.delete__btn {
  padding: 4px 10px;
  border-radius: 30px;
  outline: none;
  background-color: rgb(144, 52, 52);
  color: white;
  border: none;
}

.update__btn {
  padding: 4px 10px;
  border-radius: 30px;
  outline: none;
  background-color: rgb(231, 231, 231);
  color: #004d70;
  border: none;
}
.ck__editor {
  min-height: 200px !important;
  border: 2px solid red;
}
