body {
  background: #f2f2f2;
}
.grainTypes__page {
  width: 90%;
  margin: auto;
  background: white;
  padding: 50px 50px;
}
@media screen and (max-width: 420px) {
  .grainTypes__page {
    width: 100%;
    margin: auto;
    background: white;
    padding: 50px 25px;
  }
}
.grainTypes__card {
  width: 100%;
  height: 100%;
  position: relative;
}
.grainTypes__card img {
  width: 100%;
  height: 270px;
  object-fit: cover;
}

.grainTypesCard__image {
  max-width: 100%;
  object-fit: contain;
}
